import React from 'react';
import { jsPDF } from 'jspdf';
import { Stack } from '@mui/material';
import {
  useTeacherBenchmarksDashboardContext,
} from '../../../TeacherBenchmarksDashboard/teacherbenchmarks-dashboard-hook';
import {
  AdminBenchmarkReportWidgets,
  BENCHMARK_TYPE,
  PDF_GENERATING_ERROR,
  TeacherBenchmarkStudentGrowthReportLegendData,
} from '../../../../variables/constant';
import { extractTitle, extractWidget, renderLegend, renderText } from '../../../../utils/pdf';
import { sentryErrorLog } from '../../../../utils/sentry';
import { openSnackbar } from '../../../../components/Notifier';
import { NotifierType } from '../../../../variables/types';
import { trackEvent } from '../../../../utils/trackevent';
import { StyledIconButton } from '../../../Assignments/Assignments.styled';
import DownloadFileIcon from '../../../../components/Icons/DownloadFileIcon';
import PrintIcon from '../../../../components/Icons/PrintIcon';
import { palette } from '../../../../theme/palette';
import useUserInfo from '../../../../utils/useUserInfo';
import { DashboardWidgetsDataStatusProps } from '../../../AdminAnalytics/admin-analytics-hook';

interface ExportToPdfProps {
  lastSnowflakeUpdateTimeData?: string;
}

const ExportToPdf = ({
  lastSnowflakeUpdateTimeData,
}: ExportToPdfProps) => {
  let pdfName = '';
  let dashboard = 'Admin Dashboard Benchmark Summary';
  const {
    areWidgetsLoading, isDataMissingInAllWidgets,
    loading, setLoading, 
    isAdminReportBSGrowthDataMissing,
    dashboardWidgetsDataStatus,
  } = useTeacherBenchmarksDashboardContext();

  const indicatorWidgetDataStatus: DashboardWidgetsDataStatusProps = dashboardWidgetsDataStatus?.filter((dashboardStatus: DashboardWidgetsDataStatusProps) => {
    return dashboardStatus.widget === AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkIndicatorScores.name;
  });

  const [isLoadingUserInfo, user] = useUserInfo();
  const scoreScheme = user?.score_scheme?.scoreDetail;
  const legendData = scoreScheme?.map((item: any) => {
    return { skillTitle: item?.title, color: item?.color_code };
  });

  const generatePdf = async (print: boolean = false) => {
    try {
      setLoading?.(true);
      const pdfPadding = 10;
      const pdfMargin = 20;
      const adjustHeight = 50;
      let posX = pdfMargin;
      let posY = pdfPadding + 5;
      let elHeight = 0;
      let elWidth = 0;
      const pdfDoc = new jsPDF('p', 'pt', 'a4');
      const pageWidth = pdfDoc.internal.pageSize.getWidth();
      let headerText = 'Admin Dashoard Benchmark Summary';
      let dashboardTitle = 'Benchmark Summary';
      let dateString1: string = '';
      [posX, posY, dateString1] = await extractTitle(pdfDoc, posX, posY, pdfPadding, pdfMargin, headerText, dashboardTitle, lastSnowflakeUpdateTimeData);

      posY += 2 * pdfPadding;
      posX = pdfMargin;
      [posX, posY, elHeight, elWidth] = await extractWidget(
        pdfDoc, posX, posY, pdfPadding,
        AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkSubmissionStatusReport.name,
        pageWidth, pdfMargin, adjustHeight + 10, 10, ['#submission-status-boy-id',
          '#submission-status-moy-id', '#submission-status-eoy-id']);
      renderText(pdfDoc, posX + 55, posY + 44, BENCHMARK_TYPE.BOY);
      renderText(pdfDoc, posX + 240, posY + 44, BENCHMARK_TYPE.MOY);
      renderText(pdfDoc, posX + 428, posY + 44, BENCHMARK_TYPE.EOY);

      posX = pdfMargin;
      posY += elHeight + pdfMargin + 50;
      [posX, posY, elHeight, elWidth] = await extractWidget(
        pdfDoc, posX, posY, pdfPadding,
        AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkGrowthReport.name,
        (2 * pageWidth) / 3.6, pdfMargin, adjustHeight - 30, 10, ['#student-growth-report-legend']);
      const posYforNextWidget = posY;
      posX = pdfMargin + elWidth / 11 - 12; // Default positioning for legend
      posY += elHeight + 2 * pdfMargin - 40;
      if (!isAdminReportBSGrowthDataMissing) {
        renderLegend(pdfDoc, posX, posY, TeacherBenchmarkStudentGrowthReportLegendData as any[]);
      }

      posX = elWidth + pdfMargin + 15;
      posY = posYforNextWidget;
      [posX, posY, elHeight, elWidth] = await extractWidget(
        pdfDoc, posX, posY, pdfPadding,
        AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkScores.name,
        pageWidth / 2.2, pdfMargin, adjustHeight + 12, pdfMargin + 10);

      posX = pdfMargin;
      posY += elHeight + pdfMargin + 55;
      [posX, posY, elHeight, elWidth] = await extractWidget(
        pdfDoc, posX, posY, pdfPadding,
        AdminBenchmarkReportWidgets.BenchmarkSummary.DistrictBenchmarkIndicatorScores.name,
        pageWidth, pdfMargin, pdfMargin + 20, 10, ['#indicator-score-legend-data']);
      if (indicatorWidgetDataStatus?.dataMissing) {
        renderLegend(pdfDoc, posX + 10, posY + 40, legendData as any[]);
      }

      pdfName = `${headerText}-${dashboardTitle}-${dateString1}`;
      pdfDoc.setProperties({
        title: pdfName,
      });
      if (print) {
        pdfDoc.autoPrint({ variant: 'non-conform' });
        window.open(pdfDoc.output('bloburl'), '_blank');
      } else {
        pdfDoc.save(pdfName);
      }

      setLoading?.(false);
    } catch (e) {

      setLoading?.(false);
      sentryErrorLog(e, [
        { label: 'pdfErr', value: `Error Generating PDF for ${dashboard}` },
      ]);
      openSnackbar({ message: PDF_GENERATING_ERROR }, NotifierType.Error);
    }
  };
  const generatePdfCustomEvent = (print: boolean) => {

    if (print) {
      trackEvent('adbs_in_st_print', 'admin_dashboard_benchmark_summary_pdf_print');
    } else {
      trackEvent('adbs_in_st_download', 'admin_dashboard_benchmark_summary_download');
    }

  };


  const isDisabled =  areWidgetsLoading || isDataMissingInAllWidgets || loading;

  return (
    <Stack direction="row" justifyContent="end" marginRight={-1}  >
      <StyledIconButton
        id='download-pdf-button-admin-dashboard-benchmark-summary'
        color="primary"
        onClick={() => {
          generatePdfCustomEvent(false);
          generatePdf(false);
        }}
        className="print-ignore"
        disabled={isDisabled}
      >
        <DownloadFileIcon id='download-pdf-button-admin-dashboard-benchmark-summary-icon' fillColor={isDisabled ? palette.customBackground.disabled : ''} />
      </StyledIconButton>
      <StyledIconButton
        id='print-pdf-button-admin-dashboard-benchmark-summary'
        color="primary"
        onClick={() => {
          generatePdfCustomEvent(true);
          generatePdf(true);
        }}
        className="print-ignore"
        disabled={isDisabled}
      >
        <PrintIcon id='print-pdf-button-admin-dashboard-benchmark-summary' fillColor={isDisabled ? palette.customBackground.disabled : ''} />
      </StyledIconButton>
    </Stack >

  );
};

export default ExportToPdf;